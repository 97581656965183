<template>
  <form @submit.prevent="submit">
    <div class="overlay__title">
      {{ $t('two_fa.lets_setup') }}
    </div>

    <div class="overlay__text">
      {{ $t('two_fa.scan_code') }}<br>
      <span v-html="$t('two_fa.download')" />
    </div>

    <CodeWithQrForm
      v-model="form.fields.code"
      class="qr-code__form"
      :errors="form.errors"
      :qr="qr"
    />

    <div class="overlay__button-wrapper">
      <Button
        :label="$t('two_fa.activate')"
        :loading="form.loading"
        :disabled="!form.fields.code"
        class="button button__primary"
        @click.native="submit"
      />
    </div>
  </form>
</template>

<script>
import LoginOtpForm from '../../../forms/login_otp';
import CodeWithQrForm from '../../elements/two_fa/CodeWithQrForm.vue';
import Button from '../../elements/common/buttons/Button.vue';

export default {
  components: {
    CodeWithQrForm,
    Button,
  },
  props: {
    qr: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: new LoginOtpForm(),
    };
  },
  methods: {
    async submit() {
      if (this.form.loading) {
        return;
      }
      const response = await this.form.submit();

      if (this.form.success) {
        this.$emit('success', response);
      }
    },
    close() {
      this.$router.push({ name: 'login' });
    },
  },
};
</script>
<style lang="scss" scoped>
.overlay__text ::v-deep a {
  font-weight: 500;
  color: #3152dc;
}

.qr-code__form {
  @apply mt-6;
}

.overlay__button-wrapper {
  @apply pt-0;
}
</style>
