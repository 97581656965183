<template>
  <div>
    <div class="code__container">
      <canvas ref="qrCode" />
    </div>
    <div class="code__input">
      <InputBuilder
        :value="value"
        :required="true"
        :label="$t('two_fa.authentication_code')"
        :placeholder="$t('two_fa.enter_code')"
        :errors="errors"
        :autocomplete="false"
        class="input-settings"
        name="code"
        @input="$emit('input', $event)"
      />
    </div>
  </div>
</template>
<script>
import QRCode from 'qrcode';
import InputBuilder from '../common/inputs/InputBuilder.vue';

export default {
  components: {
    InputBuilder,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    qr: {
      type: String,
      required: true,
    },
  },
  mounted() {
    QRCode.toCanvas(this.$refs.qrCode, this.qr, { color: { light: '#F5F7F9' } });
  },
};
</script>
<style lang="scss" scoped>
  .code__container {
    @apply w-full h-[275px] rounded-lg bg-[#f5f7f9] flex items-center justify-center;
  }

  .code__input {
    @apply mt-6;
  }
</style>
