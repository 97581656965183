<template>
  <div v-if="condition" :style="keepHeight ? `height: ${height}px` : ''" class="loading">
    <div class="spinner" />
    <div v-if="showLabel" class="loading-text">
      {{ label || $t('loading') }}
    </div>
  </div>
  <div v-else ref="container" class="loading__slot">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    condition: {
      type: Boolean,
      default: true,
    },
    keepHeight: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      height: null,
    };
  },
  watch: {
    condition() {
      this.setListener();
    },
  },
  created() {
    this.setListener();
  },
  methods: {
    resize() {
      if (this.$refs.container) {
        this.height = this.$refs.container.clientHeight;
      }
    },
    setListener() {
      if (!this.condition) {
        this.$nextTick(() => {
          if (this.$refs.container) {
            this.$refs.container.addEventListener('resize', this.resize);
            this.resize();
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  .loading {
    @apply w-full flex items-center justify-center text-lg min-h-[250px] flex-col;

    &__slot {
      @apply flex-grow flex flex-col w-full min-h-0;
    }

    &.loading--blue-bg {
      .loading-text {
        @apply text-neutral-100;
      }

      .spinner {
        background: url('../../../assets/images/loaders/loading-spinner-white.svg') no-repeat;
      }
    }

    &.loading--small {
      @apply min-h-[100px];
    }

    &.loading--full {
      @apply h-full;
    }
  }

  .loading-text {
    @apply text-md text-center text-info-800 mt-[30px] font-medium;
  }

  .spinner {
    @apply h-[30px] w-[30px] animate-[rotate_1s_linear_infinite];

    background: url('../../../assets/images/loaders/loading-spinner.svg') no-repeat;
  }

  .loading--without-min-height {
    @apply min-h-0;
  }
</style>
