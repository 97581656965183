<template>
  <Loading :condition="loading" :class="{'content-wrapper': !loading}">
    <template slot-scope="{}">
      <div class="login-wrap overlay overlay--static overlay--flex">
        <div class="overlay__wrapper overlay__wrapper--center">
          <img :src="logoPath" class="login-wrap__logo">
          <RouterView
            :qr="qr"
            :sso-provider-name="ssoProviderName"
            @success="loginSuccess"
            @qr="qr = $event"
          />
        </div>
        <Footer class="footer--pre-pages footer--flex-bottom" />
      </div>
    </template>
  </Loading>
</template>
<script>
import _ from 'lodash';
import Footer from '../../elements/layout/Footer.vue';
import Loading from '../../elements/common/Loading.vue';
import measureAccountsRepository from '../../../repositories/measure_accounts_repository';
import defaultLogo from '../../../assets/images/logos/measure-studio-logo-text.svg';

export default {
  components: {
    Footer,
    Loading,
  },
  data() {
    return {
      redirectUrl: null,
      qr: null,
      loading: false,
      logoPath: defaultLogo,
      ssoProviderName: null,
    };
  },
  async created() {
    this.redirectUrl = this.getRedirectUrl();
    await this.getLogoPath();
  },
  methods: {
    getRedirectUrl() {
      if (this.$route.query.redirect) {
        // build whole URL to avoid open redirection
        return `${window.location.protocol}//${window.location.host}/${this.$route.query.redirect}`;
      }
      return '/app';
    },
    loginSuccess(response) {
      this.loading = true;

      if (this.$gtag && response.signInCount === 1) {
        this.$gtag.event('first_login', { event_category: 'registration_flow' });
      }

      window.location = this.redirectUrl;
    },
    async getLogoPath() {
      this.loading = true;
      const loginPageSlug = this.$route.params.loginPageSlug;
      if (!_.isNil(loginPageSlug)) {
        const response = await measureAccountsRepository.getByLoginSlug(loginPageSlug);
        this.logoPath = response.imageUrl;
        this.ssoProviderName = response.providerName;
      }
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.login-wrap {
  @apply bg-white;

  &__logo {
    @apply mx-auto block h-[75px] mb-[60px] sm:mb-[80px];
  }
}
</style>
